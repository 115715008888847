import store from "../store";

export const getPlaceId = () => {
  return store.getState().auth.user?.placeId;
};

export const getUsername = () => {
  return store.getState().auth.user?.username;
};

export const getAccessRight = () => {
  return store.getState().auth.user?.RoleActions?.name;
};

export const getHakAkses = () => {
  return store.getState().auth.user?.RoleActions?.action;
};

export const getName = () => {
  return store.getState().auth.user?.name;
};

export const getConfigId = () => {
  return store.getState().auth.user?.Configs?.id;
};
