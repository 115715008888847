import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { Paper, Hidden, makeStyles } from "@material-ui/core";

import routes from "../Routes/routes";
import Login from "../Assets/Ilus_Logiin.png";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      fontFamily: "Roboto, 'sans-serif'",
    },
  },
  wrapper: {
    display: "flex",
    height: "100%",
    padding: "120px 25px 120px 25px",
    backgroundColor: "#fff",
    overflow: "auto",
  },
  imgDiv: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
  },
  sloganImg: {
    alignSelf: "center",
    width: 700,
    height: 391,
  },
  sloganDiv: { paddingLeft: "25px" },
  sloganHeader: { color: "#363636" },
  sloganContent: { color: "#747070" },
  sloganLink: { textDecoration: "none", color: "#2699fb" },
  formDiv: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    width: "90%",
  },
  formHeader: { display: "block", textAlign: "center" },
  logoImg: {
    maxWidth: 250,
    maxHeight: 150,
    width: "auto",
    height: "auto",
  },
  paper: {
    width: 400,
    padding: 15,
  },
}));

const AuthLayout = (props) => {
  const classes = useStyles();
  const { children } = props;
  const history = useHistory();

  useEffect(() => {
    if (props.isLoggedIn) {
      history.push(routes.ACCOUNT);
    }
  }, [props.isLoggedIn, history]);

  return (
    <div className={classes.wrapper}>
      <Hidden mdDown>
        <div className={classes.imgDiv}>
          <img src={Login} alt="placeholder" className={classes.sloganImg} />
          <div className={classes.sloganDiv}>
            <h3 className={classes.sloganHeader}>Pilih Hanya Yang Terbaik</h3>
            <p className={classes.sloganContent}>
              Assist.id adalah sistem informasi kesehatan terbaik untuk klinik,
              praktek pribadi Anda. Berbagai fitur tersedia untuk Anda.
            </p>
            <a
              href="https://fdcdentalclinic.co.id"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.sloganLink}
            >
              Pelajari lebih lanjut &gt;
            </a>
          </div>
        </div>
      </Hidden>
      <div className={classes.formDiv}>
        <Paper className={classes.paper}>
          <h3 className={classes.formHeader}>
            <img
              src={
                "https://fdcdentalclinic.co.id/wp-content/uploads/2020/01/logo-fdc-header-web-polos.png"
              }
              alt="FDC"
              className={classes.logoImg}
            />
          </h3>
          {children}
        </Paper>
      </div>
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

AuthLayout.propTypes = {
  children: PropTypes.any,
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps)(AuthLayout);
