import { SET_USER, SET_TOKEN, LOGOUT } from "./action-types";

export const loginSuccess = (data) => (dispatch) => {
  dispatch(setUser(data));
  dispatch(setToken(data.id));
};

export const logout = (isIdle = false) => (dispatch) => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  return dispatch({
    type: LOGOUT,
    payload: { isIdle },
  });
};

export const setUser = (data) => (dispatch) => {
  localStorage.setItem("user", JSON.stringify(data));
  return dispatch({
    type: SET_USER,
    payload: data,
  });
};

export const setToken = (data) => (dispatch) => {
  localStorage.setItem("token", data);
  return dispatch({
    type: SET_TOKEN,
    payload: data,
  });
};
