import React, { useState } from "react";
import { useHistory } from "react-router";

import { makeStyles, TextField, Button, Typography } from "@material-ui/core";

import routes from "../../Routes/routes";
import { palette } from "../../theme";
import { forgotPassword } from "../../Services/auth";
import { isEmpty } from "../../Utils/validations";
import { setInfo } from "../../Utils/showSnackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  textField: {
    paddingTop: 25,
    textAlign: "center",
  },
  space: {
    paddingBottom: 35,
  },
  lupaPassword: {
    cursor: "pointer",
    color: palette.titleText,
    fontWeight: "bold",
    paddingTop: "13px",
    width: "100%",
  },
}));

const ForgotPass = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [username, setUsername] = useState("");
  const [error, setError] = useState({});

  const checkError = () => {
    const usernameError = isEmpty(username);
    setError({ username: usernameError });
    return !!usernameError;
  };

  const handleSubmit = () => {
    if (checkError()) {
      return;
    }

    forgotPassword(username).then((res) => {
      if (res.data.data === "Check your email") {
        setInfo(
          "Sukses! Permintaan perubahan kata sandi telah dikirim ke email Anda"
        );
        history.push(routes.LOGIN);
      }
    });
  };

  return (
    <div className={classes.root}>
      <div>
        <TextField
          label="Email"
          className={classes.space}
          placeholder="Email"
          value={username}
          fullWidth
          onChange={(event) => setUsername(event.target.value)}
          error={error.username && isEmpty(username)}
          helperText={
            error.username && isEmpty(username)
              ? "Email tidak boleh kosong"
              : " "
          }
        />
      </div>
      <div className={classes.textField}>
        <Button
          disableElevation
          variant="contained"
          size="large"
          fullWidth
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.lupaPassword}
          onClick={() => history.push(routes.LOGIN)}
        >
          Cancel
        </Typography>
      </div>
    </div>
  );
};

export default ForgotPass;
