/* eslint-disable import/no-anonymous-default-export */
import { SET_SNACKBAR, SET_LOADING, CLEAR_LOADING } from "./action-types";

const initialState = {
  snackbar: { open: false, variant: "info", message: "" }, // must have variant, otherwise error
  loading: { setting: true },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.name]: action.payload.isLoading,
        },
      };

    case CLEAR_LOADING:
      return {
        ...state,
        loading: {},
      };

    case SET_SNACKBAR:
      return {
        ...state,
        snackbar: action.payload,
      };

    default:
      return state;
  }
}
