/* eslint-disable import/no-anonymous-default-export */
import { SET_USER, SET_TOKEN, LOGOUT } from "./action-types";

const localToken = localStorage.getItem("token");
const localUser = localStorage.getItem("user");

const token = localToken;
const user = localUser;

const initialState = {
  user: user ? JSON.parse(user) : {},
  isLoggedIn: !!token,
  token: token || "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        user: {},
        token: "",
        isLoggedIn: false,
      };
    default:
      return state;
  }
}
