import React from "react";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Header from "./Header";
import Sidebar from "./Sidebar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  sidebarWrapper: {
    display: "flex",
    height: "100vh",
  },
  contentWrapper: {
    width: "calc(100% - 70px)",
    display: "inline-block",
    maxHeight: "100vh",
    overflow: "auto",
    backgroundColor: "#f5f9fc",
  },
  
}));

const BaseLayout = (props) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <div className={classes.root}>
      <div className={classes.sidebarWrapper}>
        <Sidebar />
      </div>
      <div className={classes.contentWrapper}>
        <Header />
        {children}
      </div>
    </div>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.any,
};

export default BaseLayout;
