import { setSnackbar } from "../Store/General/actions";
import store from "../Store/store";

export const setSuccess = (message) => {
  store.dispatch(setSnackbar("success", message));
};

export const setWarning = (message) => {
  store.dispatch(setSnackbar("warning", message));
};

export const setError = (message) => {
  store.dispatch(setSnackbar("error", message));
};

export const setInfo = (message) => {
  store.dispatch(setSnackbar("info", message));
};
