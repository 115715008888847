import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "@fontsource/roboto";
import { Font } from "@react-pdf/renderer";
import { ThemeProvider } from "@material-ui/core/styles";

import RobotoBold from "./Fonts/Roboto/Roboto-Medium.ttf";
import RobotoRegular from "./Fonts/Roboto/Roboto-Regular.ttf";

import theme from "./theme";
import Routing from "./Routing";
import store from "./Store/store";
import Snackbar from "./Components/Snackbar";
import Logger from "./Services/error-handling/Logger";

function App() {
  registerFont();
  return (
    <Provider store={store}>
      <Logger>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routing />
          </BrowserRouter>
          <Snackbar />
        </ThemeProvider>
      </Logger>
    </Provider>
  );
}

const registerFont = () => {
  Font.register({
    family: "Roboto",
    fonts: [{ src: RobotoRegular }, { src: RobotoBold, fontWeight: "bold" }],
  });
};

export default App;
