import agent from "./agent";

export const loginUser = (username, password) => {
  return agent.post("/accounts/loginAccount", { email: username, password });
};

export const forgotPassword = (email) => {
  return agent.post("/accounts/forgotPassword", { email });
};

export const updatePassword = (token, pass, confPass) => {
  return agent.post("/accounts/updatePassword", { token, pass, confPass });
};
