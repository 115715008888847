import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { makeStyles, Typography, Button, TextField } from "@material-ui/core";

import { updatePassword } from "../../Services/auth";
import { isEmpty } from "../../Utils/validations";
import { setSuccess } from "../../Utils/showSnackbar";

import { palette } from "../../theme";
import routes from "../../Routes/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  title: {
    color: palette.titleText,
  },
  textField: {
    maxWidth: "288px",
    width: "100%",
  },
  textFieldContainer: {
    paddingTop: 40,
  },
}));

const ChangePassword = (props) => {
  const classes = useStyles();
  const [passwordBaru, setPasswordBaru] = useState("");
  const [konfirmasiPass, setKonfirmasiPass] = useState("");
  const [error, setError] = useState({});
  const { token } = useParams();
  const history = useHistory();

  const checkError = () => {
    const passBaruError = isEmpty(passwordBaru);
    const konfirmasiPassError = isEmpty(konfirmasiPass);
    setError({
      passwordBaru: passBaruError,
      konfirmasiPass: konfirmasiPassError,
    });
    return !!passBaruError || !!konfirmasiPassError;
  };

  const handleSubmit = () => {
    if (checkError()) {
      return;
    }

    updatePassword(token, passwordBaru, konfirmasiPass).then((res) => {
      setSuccess("Sukses! Ganti password berhasil dilakukan");
      history.push(routes.LOGIN);
    });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h2" color="textPrimary" className={classes.title}>
        Ganti Password{" "}
      </Typography>
      <div className={classes.textFieldContainer}>
        <TextField
          className={classes.textField}
          label="Password Baru"
          type="password"
          value={passwordBaru}
          onChange={(event) => setPasswordBaru(event.target.value)}
          error={error.passwordBaru && isEmpty(passwordBaru)}
          helperText={
            error.passwordBaru && isEmpty(passwordBaru)
              ? "Password tidak boleh kosong"
              : " "
          }
        />
        <TextField
          className={classes.textField}
          label="Konfirmasi Password"
          type="password"
          value={konfirmasiPass}
          onChange={(event) => setKonfirmasiPass(event.target.value)}
          error={error.konfirmasiPass && isEmpty(konfirmasiPass)}
          helperText={
            error.konfirmasiPass && isEmpty(konfirmasiPass)
              ? "Password tidak boleh kosong"
              : " "
          }
        />
      </div>
      <Button
        disableElevation
        variant="contained"
        size="large"
        fullWidth
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </div>
  );
};

export default ChangePassword;
