import { getHakAkses } from "../Store/Auth/selector";

export default function useCekHakAkses() {
  const roles = getHakAkses();

  if (!roles) {
    return () => false;
  }

  return (role = "") => roles.some((val) => val === role);
}
