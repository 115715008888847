import React, { useState } from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";

import {
  Typography,
  makeStyles,
  Button,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { palette } from "../../theme";
import routes from "../../Routes/routes";
import { loginUser } from "../../Services/auth";
import { isEmpty } from "../../Utils/validations";
import { loginSuccess } from "../../Store/Auth/actions";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  lupaPassword: {
    cursor: "pointer",
    color: palette.titleText,
    fontWeight: "bold",
    paddingTop: "13px",
    width: "100%",
  },
  textField: {
    paddingTop: 25,
    textAlign: "center",
  },
  space: {
    paddingBottom: 35,
  },
}));

const Login = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { dispatch } = props;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState({});

  const checkError = () => {
    const usernameError = isEmpty(username);
    const passwordError = isEmpty(password);
    setError({ username: usernameError, password: passwordError });
    return usernameError || passwordError;
  };

  const handleLogin = () => {
    if (checkError()) {
      return;
    }

    loginUser(username, password)
      .then((res) => {
        dispatch(loginSuccess(res.data.data));
      })
      .catch(console.error);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  const handleVisible = () => {
    setVisible(!visible);
  };

  const handleLupaPassword = () => {
    history.push(routes.FORGOTPASS);
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Username / Email"
          fullWidth
          placeholder="Username / Email"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          className={classes.space}
          error={error.username && isEmpty(username)}
          helperText={
            error.username && isEmpty(username)
              ? "Username tidak boleh kosong"
              : " "
          }
        />
        <TextField
          label="Password"
          fullWidth
          placeholder="Password"
          type={visible ? "text" : "password"}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          className={classes.space}
          error={error.password && isEmpty(password)}
          helperText={
            error.password && isEmpty(password)
              ? "Password tidak boleh kosong"
              : " "
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {visible ? (
                  <VisibilityOff cursor="pointer" onClick={handleVisible} />
                ) : (
                  <Visibility cursor="pointer" onClick={handleVisible} />
                )}
              </InputAdornment>
            ),
          }}
        />
        <div className={classes.textField}>
          <Button
            type="submit"
            disableElevation
            variant="contained"
            size="large"
            fullWidth
          >
            Login
          </Button>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.lupaPassword}
            onClick={handleLupaPassword}
          >
            Lupa Password
          </Typography>
        </div>
      </form>
    </div>
  );
};

export default connect()(Login);
